//components
import { Container } from '@/components/Grid';
import { SectionTitle } from '@/components/SectionTitle';
import { ContentPill } from '@/components/ContentPill';

//styles
import {
  SideCTAWrapper,
  TitleWrapper,
  CTAs,
  StyledButton,
  Columns,
} from './SideCTA.styles';

//assets
import ClickIcon from '@/public/images/icons/loadsmart-collection/012-sg-try-shipperguide.svg';
import DemoIcon from '@/public/images/icons/loadsmart-collection/013-sg-schedule-a-demo.svg';

export function SideCTA() {
  return (
    <>
      <SideCTAWrapper>
        <Container>
          <TitleWrapper>
            <SectionTitle
              title={
                <>
                  Get in touch with <br /> experts and create <br /> an account.
                </>
              }
              subtitle={`Ready to get started?`}
              variant="left"
            />
            <CTAs>
              <StyledButton as="a" href="#hubspot-form">
                REQUEST A DEMO
              </StyledButton>
            </CTAs>
          </TitleWrapper>
          <Columns>
            <ContentPill
              content={{
                icon: <DemoIcon width="32" height="32" />,
                title: 'Schedule a Demo',
                description: (
                  <>
                    Our team of experts will guide you through ShipperGuide TMS
                    and provide a personalized advise.
                  </>
                ),
                link: {
                  label: 'Request a Demo',
                  url: '/shipper/shipperguide/demo/',
                  external: false,
                },
              }}
            />
            <ContentPill
              content={{
                icon: <ClickIcon width="32" height="32" />,
                title: 'Try ShipperGuide TMS',
                description: (
                  <>
                    Use your work email to create an account and start exploring
                    ShipperGuide TMS within minutes.
                  </>
                ),
                link: {
                  label: 'Sign Up & Try',
                  url: `${process.env.SHIPPERGUIDE_URL}/signup`,
                  external: true,
                },
              }}
            />
          </Columns>
        </Container>
      </SideCTAWrapper>
    </>
  );
}
