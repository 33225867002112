import styled from 'styled-components';

//components
import { Container } from '@/components/Grid';
import {
  MirandaBrandButton,
  MirandaBrandHollowButton,
} from '@/components/Button/BaseButton';

//helpers
import { screen } from '@/helpers/screen';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

export const HeroWrapper = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 803px;
  background: linear-gradient(
    180deg,
    rgb(${getToken('color-primary-100')}) 99%,
    rgb(${getToken('color-accent-20')}) 1%
  );

  background: rgb(${getToken('color-primary-100')})
    url(/images/shipperguide/hero/header_xxlg.svg) center bottom;
  background-size: cover;
  overflow-x: hidden;
  padding-bottom: 120px;

  ${screen.md} {
    min-height: 1140px;
    padding-bottom: 120px;
  }

  ${screen.lg} {
    min-height: 660px;
    padding-bottom: 100px;
  }

  ${screen.xxlg} {
    min-height: 857px;
    padding-bottom: 50px;
  }
`;

export const ContainerLogo = styled.div`
  display: flex;
  width: fit-content;
  align-items: baseline;
  margin: 0;
  color: rgb(${getToken('color-text-inverted')});
  font-family: ${getToken('font-family-title')};
  font-weight: ${getToken('font-weight-regular')};

  font-size: 22px;
`;

export const TopLogoWrapper = styled.div`
  width: 158px;
  height: 20px;
  margin: ${getToken('spacing-10')} 0;

  svg {
    fill: rgb(${getToken('color-brand')});
  }
`;

export const ContainerHero = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
  height: 100%;
  gap: 20px;

  ${screen.lg} {
    flex-direction: row;
  }
`;

export const ContentHolder = styled.div`
  margin: auto;

  ${screen.lg} {
    width: 50%;
  }

  ${screen.xxlg} {
    width: 45%;
  }
`;

export const MediaHolder = styled.div`
  margin: auto;
  position: relative;

  ${screen.lg} {
    width: 55%;
    padding-left: 20px;
  }

  ${screen.xxlg} {
    width: 55%;
  }
`;

export const AnimationHolder = styled.div`
  border-radius: 16px;
  overflow: hidden;
  background: rgb(${getToken('color-background-primary')});

  ${screen.lg} {
    width: calc(100% + 60px) !important;
  }
`;

//award badges

export const AwardsHolder = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: max-content;

  position: absolute;
  bottom: -40px;
  left: 54px;

  z-index: 10;

  ${screen.md} {
    gap: 16px;
    left: 182px;
    bottom: -50px;
  }
  ${screen.lg} {
    left: 28px;
    bottom: -60px;
  }
`;

export const BadgeContainer = styled.div`
  width: 45px;
  height: auto;
  margin: auto;

  ${screen.md} {
    width: 68px;
  }

  ${screen.xxlg} {
    width: 78px;
    height: auto;
  }
`;

export const Badge = styled.img`
  max-width: 100%;
  height: auto;
`;

//------//

export const TagSlogan = styled.span`
  color: rgb(${getToken('color-success-20')});
  font-family: ${getToken('platform-font-default')};
  font-weight: ${getToken('font-weight-regular')};

  font-size: 14px;
  line-height: 21px;
`;

export const HeroTitle = styled.h1`
  color: rgb(${getToken('color-text-inverted')});
  font-family: ${getToken('platform-font-title')};
  font-weight: ${getToken('font-weight-bold')};
  margin: 0;
  font-size: 28px;
  line-height: 43.7px;

  strong {
    color: rgb(${getToken('color-brand')});
  }

  ${screen.md} {
    font-size: 38px;
    line-height: 43.7px;
    padding-right: 50px;
  }

  ${screen.lg} {
    padding-right: 0;
    font-size: 42px;
    line-height: 52px;
  }

  ${screen.xxlg} {
    font-size: 56px;
    line-height: 66.7px;
  }
`;

export const HeroContent = styled.p`
  color: rgb(${getToken('color-success-20')});
  font-family: ${getToken('platform-font-default')};
  font-weight: ${getToken('font-weight-regular')};

  font-size: 14px;
  line-height: 22.4px;

  margin: 35px 0;

  ${screen.md} {
    font-size: 16px;
    line-height: 25.6px;
  }

  ${screen.xxlg} {
    font-size: 20px;
    line-height: 32px;
  }
`;

export const CTAHolder = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  justify-content: center;
  text-align: center;

  a {
    width: 100%;
    margin: 10px;
  }

  ${screen.md} {
    display: grid;
    grid-auto-flow: column;
    justify-content: flex-start;

    a {
      width: auto;
    }
  }
`;

export const BrandButton = styled(MirandaBrandButton)`
  font-family: ${getToken('platform-font-default')};
  font-weight: ${getToken('font-weight-bold')};
  margin-left: 0px !important;
  font-size: 14px;
  line-height: 14px;
  padding: 14.5px;
  border: 1px solid rgb(${getToken('color-brand')});
  width: max-content;

  ${screen.md} {
    padding: 12px;
  }

  ${screen.xxlg} {
    font-size: 16px;
    line-height: 16px;
    padding: 15px;
  }
`;
